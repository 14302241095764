export const CookieUtil = {
  set(
    key: string,
    value: any,
    expires: string,
    config?: { path?: string; encrypted: boolean },
  ) {
    let stringify = JSON.stringify(value)
    if (config?.encrypted) {
      stringify = btoa(stringify)
    }
    document.cookie = `${key}=${stringify}; path=${
      config?.path || '/'
    }; expires=${expires};`

    return {
      path: config?.path,
      expires,
    }
  },
  get(key: string, encrypted?: boolean) {
    const name = key + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return encrypted
          ? atob(c.substring(name.length, c.length))
          : c.substring(name.length, c.length)
      }
    }
    return null
  },
  getJSON<T>(key: string, encrypted?: boolean): T | null {
    try {
      let stringify = this.get(key)
      if (stringify === null) {
        throw new Error('Cannot get JSON data of' + key)
      }
      if (encrypted) {
        stringify = atob(stringify)
      }
      return JSON.parse(stringify)
    } catch (error) {
      return null
    }
  },
  clear(key: string) {
    document.cookie = key + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  },
}
