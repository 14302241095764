import React, { lazy, Suspense } from 'react'
import { RouteComponentProps, RouteProps, Switch } from 'react-router-dom'

import { Loading } from 'components/Loading'
import { HeaderContent } from 'models/app'
import { PATH_NAMES } from 'configs/path-names'

import { ProtectRoute } from './ProtectRoute'
import { PublicRoute } from './PublicRoute'

export interface RouteConfig extends Pick<RouteProps, 'path' | 'exact'> {
  component: React.FC<RouteProps & RouteConfig>
  header: HeaderContent
  roles?: string[]
  redirect?: string
  disabled?: boolean
  route?: React.FC<RouteConfig>
  autoScrollTop?: boolean
  onBeforeRender?(
    routeProps: RouteConfig,
    renderProps: RouteComponentProps<any, any, any>,
  ): void
}

const routes: RouteConfig[] = [
  {
    component: lazy(() => import('pages/Home')),
    path: PATH_NAMES.HOME,
    exact: true,
    route: ProtectRoute,
    header: {
      title: 'DASHBOARD WORKSPACE',
    },
  },
  {
    component: lazy(() => import('pages/Login')),
    path: PATH_NAMES.LOGIN,
    header: {
      title: 'Sign In',
      subTitle: 'login',
    },
  },
  {
    component: lazy(() => import('pages/Upload')),
    path: PATH_NAMES.UPLOAD,
    route: ProtectRoute,
    header: {
      title: 'Upload Dataset',
    },
    autoScrollTop: true,
  },
  {
    component: lazy(() => import('pages/DatasetCreate')),
    path: PATH_NAMES.DATASET_CREATE,
    route: ProtectRoute,
    header: {
      title: 'Create New Dataset',
    },
    onBeforeRender: (_, renderProps) => {
      if (
        !(renderProps.location.state?.flow && renderProps.location.state?.app)
      ) {
        renderProps.history.push(PATH_NAMES.HOME)
      }
    },
    autoScrollTop: true,
  },
  {
    component: lazy(() => import('pages/DatasetUpdate')),
    path: PATH_NAMES.DATASET_UPDATE(false),
    route: ProtectRoute,
    header: {
      title: 'Update Dataset',
    },
    autoScrollTop: true,
  },
  {
    component: lazy(() => import('pages/DatasetList')),
    path: PATH_NAMES.DATASETS,
    exact: true,
    route: ProtectRoute,
    header: {
      title: 'Datasets',
    },
    autoScrollTop: true,
  },
  {
    component: lazy(() => import('pages/DatasetDetail')),
    path: PATH_NAMES.DATASET_DETAIL(false),
    route: ProtectRoute,
    exact: true,
    header: {
      title: 'Dataset Detail',
    },
    autoScrollTop: true,
  },
  // -- asset routes
  {
    component: lazy(() => import('pages/AssetCreate')),
    path: PATH_NAMES.ASSET_CREATE,
    route: ProtectRoute,
    header: {
      title: 'Create New Asset',
    },
    autoScrollTop: true,
  },
  {
    component: lazy(() => import('pages/AssetList')),
    path: PATH_NAMES.ASSETS,
    route: ProtectRoute,
    exact: true,
    header: {
      title: 'Assets',
    },
    autoScrollTop: true,
  },
  {
    component: lazy(() => import('pages/ForgotPassword')),
    path: PATH_NAMES.FORGOT_PASSWORD,
    header: {
      title: 'Forgot Password',
    },
  },
  {
    component: lazy(() => import('pages/ChangePassword')),
    path: PATH_NAMES.CHANGE_PASSWORD,
    route: ProtectRoute,
    header: {
      title: 'Change Password',
    },
  },
  {
    component: lazy(() => import('pages/ResetPassword')),
    path: PATH_NAMES.RESET_PASSWORD,
    header: {
      title: 'Reset Password',
    },
  },
  {
    component: lazy(() => import('pages/NotFound')),
    path: '*',
    header: {
      title: 'Page not found',
    },
  },
]

const Routes = () => {
  return (
    <Suspense fallback={<Loading className="flex-grow-1" loading />}>
      <Switch>
        {routes.map((item, index) => {
          const { disabled, route: CustomRoute } = item
          if (disabled) return null
          if (CustomRoute) {
            return <CustomRoute {...item} key={index} />
          }
          return <PublicRoute {...item} key={index} />
        })}
      </Switch>
    </Suspense>
  )
}

export default Routes
