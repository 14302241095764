import { DataResponse, RequestResponse } from 'models/api'
import { SubscriptionApp } from 'models/user'
import { httpRequest } from 'services/http-request'

export const getSubscribedApp = async (
  clientId: number,
): Promise<DataResponse<{ apps: SubscriptionApp[] }>> => {
  try {
    const res: RequestResponse<SubscriptionApp[]> = await httpRequest.get(
      '/subscription/applications',
      {
        showLoading: true,
        retry: 1,
        params: {
          client_id: clientId,
        },
      },
    )

    if (!res.data.success) {
      throw new Error(res.data.message)
    }

    return {
      status: true,
      data: { apps: res.data.data.sort((a, b) => a.id - b.id) },
      message: res.data.message,
    }
  } catch (error) {
    return Promise.reject({
      status: false,
      message: error.message,
    })
  }
}

export const UserAPI = {
  getSubscribedApp,
}
