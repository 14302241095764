import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Asset } from 'models/asset'
import { Dataset } from 'models/dataset'

export type DatasetState = {
  list: Dataset[] | null
  detail: Dataset | null
  pagination: {
    total: number // total items
    pageSize: number
    current: number
    filter?: {
      assetId?: Asset['id']
      name?: Dataset['name']
    }
  }
}

export const initialState: DatasetState = {
  list: null,
  detail: null,
  pagination: {
    total: 0,
    pageSize: 15,
    current: 1,
  },
}

const slice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    setList(
      state,
      action: PayloadAction<Pick<DatasetState, 'list'> & { total: number }>,
    ) {
      state.list = action.payload.list
      state.pagination.total = action.payload.total
    },
    removeItem(state, action: PayloadAction<{ id: Dataset['id'] }>) {
      const removedIndex = state.list?.findIndex(
        (item) => item.id === action.payload.id,
      )
      if (removedIndex && removedIndex !== -1) {
        state.list?.splice(removedIndex, 1)
      }
    },
    setDetail(state, action: PayloadAction<DatasetState['detail']>) {
      state.detail = action.payload
    },
    updatePagination(
      state,
      action: PayloadAction<Partial<DatasetState['pagination']>>,
    ) {
      state.pagination = { ...state.pagination, ...action.payload }
    },
    resetPagination(state) {
      state.pagination = initialState.pagination
    },
  },
})

export const { actions } = slice

export default slice.reducer
