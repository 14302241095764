import { useMergedState } from 'hooks/useMergedState'
import { useCallback, useEffect, useMemo } from 'react'

type State = {
  loading: boolean
  error?: any
}

export const useAsyncCallback = <
  C extends (...args: any) => Promise<any>,
  A extends any[]
>(
  cb: C,
  initArgs?: A,
) => {
  const [state, updateState] = useMergedState<State>({
    loading: false,
    error: null,
  })

  const triggerRequest = useCallback(async (...args: any) => {
    updateState({ loading: true, error: null })
    try {
      await cb(...args)
      updateState({ error: null })
    } catch (error) {
      updateState({ error })
    }
    updateState({ loading: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initArgs) {
      triggerRequest(...initArgs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => {
    return [state.loading, state.error, triggerRequest as C]
  }, [state, triggerRequest])
}
