import { FC } from 'react'
import classnames from 'classnames'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import './style.scss'

import { FlexBox } from 'components/FlexBox'

export const LIST_ICON = {
  trash: <i className="fas fa-trash-alt"></i>,
  closeCircle: <i className="fas fa-times-circle text-danger" />,
  more: <i className="fas fa-ellipsis-h text-secondary" />,
  upload: <i className="fas fa-file-upload"></i>,
  info: (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="info-circle"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
      <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
    </svg>
  ),
  success: (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="check-circle"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path>
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    </svg>
  ),
  error: (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="close-circle"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path>
      <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    </svg>
  ),
  externalLink: <i className="fas fa-external-link-alt" />,
}

type IconButtonProps = {
  icon: keyof typeof LIST_ICON
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string
  size?: string | number
  hover?: boolean
  color?: string
  tooltip?: string
}
export const IconButton: FC<IconButtonProps> = (props) => {
  return (
    <FlexBox
      className={classnames(`icon-btn`, props.className, {
        'icon-btn--hover': props.hover,
      })}
      onClick={props.onClick}
      style={{ fontSize: props.size, color: props.color }}
    >
      {props.tooltip ? (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              {props.tooltip}
            </Tooltip>
          }
        >
          <span className="icon">{LIST_ICON[props.icon]}</span>
        </OverlayTrigger>
      ) : <span className="icon">{LIST_ICON[props.icon]}</span>
      }
    </FlexBox>
  )
}
