import { UnexpectedError } from 'features/errors/UnexpectedError'
import React from 'react'

export type ErrorBoundaryProps = {
  fallbackUI?: React.ReactNode
}
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; error: any }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error('componentDidCatch', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.fallbackUI) return this.props.fallbackUI
      return <UnexpectedError />
    }

    return this.props.children
  }
}
