import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialState = {
  leavePageByFunction: boolean
  visibleConfirm: boolean
}

const initialState: InitialState = {
  leavePageByFunction: false,
  visibleConfirm: false,
}

const leavePage = createSlice({
  name: 'confirmLeavePage',
  initialState: initialState,
  reducers: {
    updateStatus: (state, action: PayloadAction<any>) => {
      console.log(action)
      state.visibleConfirm = action.payload.visible
    },
    visibleConfirmByFunction: (state) => {
      state.leavePageByFunction = true
    },
    visibleConfirmByControl: (state) => {
      state.leavePageByFunction = false
    },
  },
})

const { actions, reducer } = leavePage
export const {
  updateStatus,
  visibleConfirmByFunction,
  visibleConfirmByControl,
} = actions
export default reducer
