import { NonNullableFiled, Nullable } from 'utils/types'
import { AssetUploadObject } from './asset'
import { Building } from './common-db'
import { SelectOption } from './form'

export enum UploadFlow {
  Drone = 'drones',
  OtherDevices = 'other devices',
  DroneWSI = 'dronesSWI',
}

// radio box
export enum AssetStatus {
  New = 'new',
  Old = 'old',
}

export enum UploadType {
  Image = 'image',
  Video = 'video',
}

export enum DatasetStatus {
  PendingUpload = 'PENDING UPLOAD',
  InProcess = 'IN PROCESS',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
}

export enum DatasetPathInS3 {
  DroneLog = 'drone-logs',
  Image = 'images',
  Document = 'documents',
  Model = 'models',
  Asset = 'asset',
  AssetFaceImages = 'images',
  AssetDiagram = 'technical-drawings',
}

export type PreSignUrl = {
  url: string
  key: string
  AWSAccessKeyId: string
  policy: string
  signature: string
}

export type CreatingDataset = {
  name: string
  app: Nullable<SelectOption>
  assetMaterial: string
  assetName: string
  assetAddress: string
  assetAddressDetails: any
  asset: Building | null
  assetOption: Nullable<SelectOption>
  assetStatus: AssetStatus
  model?: File[] | null
  uploadType: UploadType
  droneLogs?: File[] | null
  documents?: File[] | null
  assetFaces?: AssetUploadObject[] | null
  flow: UploadFlow | null
}

export type CreatedDataset = NonNullableFiled<Pick<CreatingDataset, 'app'>> &
  Omit<CreatingDataset, 'app'> & {
    id: number
    datasetS3Config: PreSignUrl
    assetS3Config?: PreSignUrl
  } & Pick<Dataset, 'status' | 'building_ID' | 'building_URL'>

export type DatasetError = {
  code: string
  title: string
  data?: boolean | Array<string[] | string>
}

export type Dataset = {
  id: number
  name: string
  status: DatasetStatus
  type: UploadFlow
  uploadType?: UploadType
  faces?: string[]
  client: {
    id: number
    name: string
  }
  building_ID: string
  building_URL: Building['building_URL']
  // asset: Pick<Building, 'building_ID' | 'building_URL'> | null
  application: {
    id: number
    name: string
    code?: string
  }
  inspection_id?: number
  standard?: 'NEN' | 'PFI'
  created: string
  updated: string
  errors?: DatasetError[]
}

export type PercentUpload = {
  fileId?: string
  percent: number | null
  percentPrev?: number
}

export type PercentUploads = {
  [key: string]: PercentUpload
}

export type CancelToken = {
  cancel: () => void
  token: object | any
}

export type CancelTokens = {
  [key: string]: CancelToken | any
}

export type OptionsApplication = {
  value: string | number
  label: string
}

export type UploadInfo = {
  [key: string]: {
    dataset_id: string
    key: string
    uploadId: string
    cancelTokensChunk: CancelToken[]
  }
}
