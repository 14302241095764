import { ModalProps, OriginModal } from './Modal'
import confirm, {
  withWarn,
  withInfo,
  withSuccess,
  withError,
  withConfirm,
  ModalStaticFunctions,
} from './confirm'

type ModalType = typeof OriginModal & ModalStaticFunctions

export const Modal = OriginModal as ModalType

function modalWarn(props: ModalProps) {
  return confirm(withWarn(props))
}

Modal.info = function infoFn(props: ModalProps) {
  return confirm(withInfo(props))
}

Modal.success = function successFn(props: ModalProps) {
  return confirm(withSuccess(props))
}

Modal.error = function errorFn(props: ModalProps) {
  return confirm(withError(props))
}

Modal.warning = modalWarn

Modal.warn = modalWarn

Modal.confirm = function confirmFn(props: ModalProps) {
  return confirm(withConfirm(props))
}
