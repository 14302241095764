export abstract class Storage<T> {
  protected readonly key: string
  protected readonly type: 'json' | 'string'
  protected readonly sensitive: boolean
  protected readonly version: number

  constructor(
    key: string,
    type: 'json' | 'string',
    sensitive: boolean,
    version: number,
  ) {
    this.key = key
    this.type = type
    this.sensitive = sensitive || false
    this.version = version
  }

  abstract set(value: T, expiresHours: number): void

  abstract clear(): void

  abstract update(value: Partial<T>, expiresHours?: number): void

  abstract checkOutdated(): void
}
