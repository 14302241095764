import React, { useMemo } from 'react'
import { Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'

import { useRouter } from 'hooks'
import { actions } from 'store/slices/auth'
import { RootState } from 'store/rootReducer'
import { PATH_NAMES } from 'configs/path-names'
import { Link } from 'react-router-dom'
import { clearToken } from 'utils/token'

type NavItem = {
  url?: string
  exact?: boolean
  label?: string
  icon?: React.ReactNode
  subMenu?: NavItem[]
  type: 'link' | 'icon' | 'button'
  events?: { [key: string]: () => void }
  requiredAuth?: boolean
  hiddenAuth?: boolean
}

type NavbarProps = {
  triggerConfirmBox: (handle: () => void, message: string) => void
}

export const NavBar: React.FC<NavbarProps> = ({ triggerConfirmBox }) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const auth = useSelector((state: RootState) => state.auth)
  const handleLogout = () => {
    dispatch(actions.clear())
    clearToken()
    router.push(PATH_NAMES.LOGIN)
  }

  const navList: NavItem[] = [
    {
      url: PATH_NAMES.HOME,
      label: 'Dashboard',
      type: 'link',
      requiredAuth: true,
      exact: true,
    },
    {
      url: PATH_NAMES.DATASETS,
      label: 'Datasets',
      type: 'link',
      requiredAuth: true,
      exact: true,
    },
    {
      url: PATH_NAMES.ASSETS,
      label: 'Assets',
      type: 'link',
      requiredAuth: true,
      exact: true,
    },
    {
      // label: 'Profile',
      // url: '/profile',
      type: 'link',
      icon: <i className="fas fa-lg fa-user-circle"></i>,
      subMenu: [
        // {
        //   // url: '/profile',
        //   label: 'Profile',
        //   type: 'link',
        // },
        {
          url: '/change-password',
          label: 'Change Password',
          type: 'button',
        },
        {
          label: 'Sign out',
          type: 'button',
          events: {
            onClick: () =>
              triggerConfirmBox(
                handleLogout,
                'Uploading. Are you sure Sign out?',
              ),
          },
        },
      ],
      requiredAuth: true,
    },
    {
      url: PATH_NAMES.LOGIN,
      label: 'Sign In',
      type: 'link',
      hiddenAuth: true,
    },
    // {
    //   label: 'Languages',
    //   type: 'icon',
    //   icon: <i className="fas fa-lg fa-globe"></i>,
    //   subMenu: [
    //     { label: 'English', type: 'button' },
    //     { label: 'Germany', type: 'button' },
    //   ],
    // },
  ]

  const hiddenDatasetsNavbarPath = useMemo(() => {
    return [
      PATH_NAMES.HOME,
      PATH_NAMES.CHANGE_PASSWORD,
      PATH_NAMES.LOGIN,
      PATH_NAMES.FORGOT_PASSWORD,
      PATH_NAMES.RESET_PASSWORD,
    ]
  }, [])

  const displayNavbar = () => {
    const enableFlightMap = auth?.enableFlightMapApp
    let result = [...navList]
    if (hiddenDatasetsNavbarPath.includes(router.pathname)) {
      result = result.filter((e) => e.url !== PATH_NAMES.DATASETS)
    }
    if (!enableFlightMap) {
      result = result.filter((e) => e.url !== PATH_NAMES.ASSETS)
    }
    return result
  }

  const newNavList = displayNavbar()

  return (
    <Nav className="nav-bar align-items-center">
      {newNavList?.map((navItem, navIndex) => {
        if (navItem.requiredAuth && !auth.isAuthenticated) {
          return undefined
        }

        if (navItem.hiddenAuth && auth.isAuthenticated) {
          return undefined
        }
        // to={navItem.url}
        return (
          <Nav.Item className="drop-down-wrapper cursor-pointer" key={navIndex}>
            {navItem.url ? (
              <Link className="nav-bar__link" to={navItem.url}>
                {navItem.label} {navItem.icon}
              </Link>
            ) : (
              <li className="nav-bar__icon">{navItem.icon}</li>
            )}
            {navItem.subMenu && (
              <div className="menu-wrapper layer-dropdown">
                <ul className="drop-down shadow-sm">
                  {navItem.subMenu.map((subMenuItem, subMenuIndex) => {
                    return (
                      <li
                        className="nav-bar__link"
                        key={subMenuIndex}
                        {...subMenuItem.events}
                      >
                        {subMenuItem.url ? (
                          <Link className="nav-bar__link" to={subMenuItem.url}>
                            {subMenuItem.label} {subMenuItem.icon}
                          </Link>
                        ) : (
                          <div className="nav-bar__icon">
                            {subMenuItem.label} {subMenuItem.icon}
                          </div>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </Nav.Item>
        )
      })}
    </Nav>
  )
}

