import { AxiosResponse } from 'axios'

export enum HttpStatusCode {
  Unauthorized = 401,
}

export type DataResponse<T extends object | null> = {
  message: string
  status: boolean
} & (T extends null ? {} : { data: T })

export type RequestResponse<T extends object | null> = AxiosResponse<
  Omit<DataResponse<T>, 'status'> & { success: boolean }
>
