import { combineReducers } from '@reduxjs/toolkit'
import appReducer from 'store/slices/app'
import authReducer from 'store/slices/auth'
import datasetReducer from 'store/slices/dataset'
import assetFlightMapReducer from 'store/slices/asset'
import leavePageReducer from 'store/slices/leavePage'

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  dataset: datasetReducer,
  assetFlightMap: assetFlightMapReducer,
  confirmLeavePage: leavePageReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer

