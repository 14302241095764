import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

import { RouteConfig } from 'routes'
import { scroller } from 'utils/dom'

export const PublicRoute: React.FC<RouteConfig> = (props) => {
  const { component: Component, onBeforeRender, ...routeProps } = props

  useEffect(() => {
    if (props.autoScrollTop) {
      scroller.scrollToTop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      {...routeProps}
      path={props.path}
      exact={props.exact}
      render={(renderProps) => {
        onBeforeRender && onBeforeRender(props, renderProps)
        return <Component {...renderProps} {...props} />
      }}
    />
  )
}
