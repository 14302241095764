import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

export * from './hooks'

export const Context = React.createContext<{
  toaster: typeof toast
} | null>(null)

export const NotificationContainer: React.FC = (props) => {
  return (
    <Context.Provider value={{ toaster: toast }}>
      <ToastContainer autoClose={2000} />
      {props.children}
    </Context.Provider>
  )
}
