import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssetFlightMap } from 'models/asset-flight-map'

export type Assets = {
  list: AssetFlightMap[] | null
  detail: AssetFlightMap | null
  pagination: {
    total: number
    pageSize: number
    current: number
  }
}

export const initialState: Assets = {
  
  list: null,
  detail: null,
  pagination: {
    total: 0,
    pageSize: 15,
    current: 1,
  },
}

const slice = createSlice({
  name: 'assetFlightMap',
  initialState,
  reducers: {
    setList(
      state,
      action: PayloadAction<Pick<Assets, 'list'> & { total: number } >,
    ) {
      state.list = action.payload.list
      state.pagination.total = action.payload.total
    },
    updatePagination(
      state,
      action: PayloadAction<Partial<Assets['pagination']>>,
    ) {
      state.pagination = { ...state.pagination, ...action.payload }
    }
  },
  
})

export const { actions } = slice

export default slice.reducer
