import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { RootState } from 'store/rootReducer'
import { RouteConfig } from 'routes'
import { Loading } from 'components/Loading'
import { PATH_NAMES } from 'configs'
import { scroller } from 'utils/dom'

export const ProtectRoute: React.FC<RouteConfig> = (props) => {
  const {
    component: Component,
    onBeforeRender,
    redirect,
    ...routeProps
  } = props

  const redirectPath = redirect || PATH_NAMES.LOGIN

  const auth = useSelector((state: RootState) => {
    return state.auth
  })

  useEffect(() => {
    if (props.autoScrollTop) {
      scroller.scrollToTop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      {...routeProps}
      render={(renderProps) => {
        onBeforeRender && onBeforeRender(props, renderProps)
        if (auth.init) {
          return <Loading loading className="flex-grow-1" />
        }
        if (!auth.isAuthenticated && !auth.init) {
          return (
            <Redirect
              to={{
                pathname: redirectPath,
                state: { from: renderProps.location.pathname },
              }}
            />
          )
        }
        return <Component {...renderProps} {...props} />
      }}
    />
  )
}
