import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HeaderContent } from 'models/app'
import { UploadType } from 'models/dataset'

interface AppState extends HeaderContent {
  loading: boolean
  isInteriorFloorApp: boolean
  typeUploadCreateDataset: UploadType
}

const initialState: AppState = {
  loading: false,
  isInteriorFloorApp: false,
  typeUploadCreateDataset: UploadType.Image,
}

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateTitle(state, action: PayloadAction<HeaderContent>) {
      const { title, subTitle } = action.payload
      state.title = title
      state.subTitle = subTitle
    },
    resetTitle(state) {
      state.title = ''
      state.subTitle = ''
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    showLoading(state) {
      state.loading = true
    },
    hideLoading(state) {
      state.loading = false
    },
    checkInteriorFloorApp(state, action: PayloadAction<boolean>) {
      state.isInteriorFloorApp = action.payload
    },
    checkUploadTypeCreateDataset(state, action) {
      state.typeUploadCreateDataset = action.payload
    },
  },
})

export const { actions } = AppSlice

export default AppSlice.reducer
