import { useState } from 'react'

export type UseBooleanValue = [
  boolean,
  () => void,
  () => void,
  (value?: boolean) => void,
]
export const useBoolean = (defaultValue?: boolean): UseBooleanValue => {
  const [bool, setBool] = useState(defaultValue || false)
  const setTrue = () => setBool(true)
  const setFalse = () => setBool(false)
  const toggle = (value?: boolean) =>
    value !== undefined ? setBool(value) : setBool((prev) => !prev)
  return [bool, setTrue, setFalse, toggle]
}
