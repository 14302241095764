export const ENV = {
  LOCAL_KEY: process.env.REACT_APP_LOCAL_KEY || 'h3z-common-upload',
  CONTACT_URL: process.env.REACT_APP_CONTACT_URL,
  FI_APP_URL: process.env.REACT_APP_FI_APP_URL,
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || '',
  COMMON_DB_API_ENDPOINT:
    process.env.REACT_APP_COMMON_DB_API_ENDPOINT ||
    'http://ec2-13-250-5-200.ap-southeast-1.compute.amazonaws.com:8000',
  COMMON_DB_V2_API_ENDPOINT:
    process.env.REACT_APP_COMMON_DB_V2_API_ENDPOINT ||
    'http://ec2-54-169-49-254.ap-southeast-1.compute.amazonaws.com:8000',
  KEY: process.env.REACT_APP_KEY || '',
  FLIGHT_APP_USER: (
    process.env.REACT_APP_FLIGHT_APP_USER || 'vy.nguyen@kyanon.digital'
  ).split(','),
  FM_APP_URL:
    process.env.REACT_APP_FM_APP_URL ||
    'http://ec2-13-213-2-111.ap-southeast-1.compute.amazonaws.com:8000',
  TOKEN_REDIRECT_FM_APP:
    process.env.REACT_APP_TOKEN_REDIRECT_FM_APP ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlBERi1UZXN0aW5nLTUyLTIyMC0zOS05NCIsImlhdCI6MTYyNzQ0MTE2Nn0.tVjCWJ2Kb2bN0ViCUYfQs-BlGo5DOrR7jEJ48VPQ-f4',
  ONEMAP_URL:
    process.env.REACT_APP_ONEMAP_URL || 'https://developers.onemap.sg',
  ALLOW_VIDEO: process.env.REACT_APP_ALLOW_VIDEO || '',
  ALLOW_IMAGE: process.env.REACT_APP_ALLOW_IMAGE || '',
  GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
  IS_MAINTENANCE: process.env.REACT_APP_IS_MAINTENANCE,

  get ACCESS_TOKEN(): string {
    return process.env.REACT_APP_ACCESS_TOKEN || ''
  },
  get REFRESH_TOKEN(): string {
    return process.env.REACT_APP_REFRESH_TOKEN || ''
  },
}

if (
  process.env.REACT_APP_HOT_LOAD_ENV &&
  process.env.NODE_ENV === 'development'
) {
  // local
  // ENV.API_ENDPOINT = `http://10.20.102.152:8000/api/v1`
}

