import { DataResponse, RequestResponse } from 'models/api'
import { Account, Client, SubscriptionApp, User } from 'models/user'
import { httpRequest } from 'services/http-request'

export const login = async (
  payload: Account,
): Promise<
  DataResponse<{
    token: string
    access_token: string
    refresh_token: string
    user: User
    client: Client
    applications: SubscriptionApp[]
  }>
> => {
  try {
    const res: RequestResponse<{
      token: string
      access_token: string
      user: User
      client: Client
      applications: SubscriptionApp[]
      refresh_token: string
      email_domain: string
    }> = await httpRequest.post('/user/signin', payload, { showLoading: true })
    const { data, message, success } = res.data

    if (!success) {
      throw new Error(res.data.message)
    }
    data.client.email_domain = data.email_domain
    return { status: true, data: data, message: message }
  } catch (error) {
    return Promise.reject({
      status: false,
      message: error.message || 'Your username or password is incorrect!',
    })
  }
}

export const refreshToken = async (
  refreshToken: string,
): Promise<
  DataResponse<{
    access_token: string
  }>
> => {
  try {
    const res: RequestResponse<{
      access_token: string
    }> = await httpRequest.post(
      '/user/token-renewal',
      {
        refresh_token: refreshToken,
      },
      {
        isRefreshingToken: true,
      },
    )

    if (!res.data.success) {
      throw new Error(res.data.message)
    }
    return { status: true, data: res.data.data, message: res.data.message }
  } catch (error) {
    return Promise.reject({
      status: false,
      message: error.message,
    })
  }
}

export const getUserProfile = async (): Promise<
  DataResponse<{
    token: string
    access_token: string
    refresh_token: string
    user: User
    client: Client
    applications: SubscriptionApp[]
  }>
> => {
  try {
    const res: RequestResponse<{
      token: string
      access_token: string
      user: User
      client: Client
      applications: SubscriptionApp[]
      refresh_token: string
      email_domain: string
    }> = await httpRequest.get('/user/profile', {
      showLoading: true,
    })
    const { data, message, success } = res.data

    if (!success) {
      throw new Error(res.data.message)
    }
    data.client.email_domain = data.email_domain
    return { status: true, data: data, message: message }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const forgotPassword = async (
  emailVerify: string,
): Promise<DataResponse<null>> => {
  try {
    const res = await httpRequest.post(
      '/user/forgot-password',
      {
        email: emailVerify,
      },
      { showLoading: true },
    )
    if (res.status !== 200) {
      throw new Error()
    }
    return {
      status: true,
      message:
        res.data?.message ||
        'An email has been sent to your email, please check your inbox!',
    }
  } catch (error) {
    return Promise.reject({
      status: false,
      message:
        error.message ||
        'The e-mail address is not assigned to any user account',
    })
  }
}

export const resetPassword = async (
  emailVerify: string,
  isGenerate: boolean,
): Promise<DataResponse<null>> => {
  try {
    const res = await httpRequest.post(
      '/user/reset-password',
      {
        email: emailVerify,
        is_reset: isGenerate,
      },
      { showLoading: true },
    )
    if (res.status !== 200) {
      throw new Error()
    }
    return {
      status: true,
      message:
        res.data?.message ||
        'An email has been sent to your email, please check your inbox!',
    }
  } catch (error) {
    return Promise.reject({
      status: false,
      message:
        error.message ||
        'The e-mail address is not assigned to any user account',
    })
  }
}

export const confirmResetPassword = async (
  uid: string,
  token: string,
  newPassword: string,
  confirmPassword: string,
): Promise<DataResponse<null>> => {
  try {
    const res = await httpRequest.post(
      '/user/confirmed-forgot-password',
      {
        uid: uid,
        token: token,
        new_password1: newPassword,
        new_password2: confirmPassword,
      },
      { showLoading: true },
    )
    if (res.status !== 200) {
      throw new Error()
    }
    return {
      status: true,
      message: res.data?.message[0] || 'Password has been reset successfully!',
    }
  } catch (error) {
    return Promise.reject({
      status: false,
      message: error.message || 'Invalid password',
    })
  }
}

export const changePassword = async (
  old_password: string,
  new_password: string,
  new_password_again: string,
): Promise<DataResponse<null>> => {
  try {
    const res = await httpRequest.post(
      '/user/change-password',
      {
        old_password: old_password,
        new_password: new_password,
        new_password_again: new_password_again,
      },
      {
        showLoading: true,
      },
    )
    if (res.status !== 200) {
      throw new Error()
    }
    const { message } = res.data

    return {
      status: true,
      message:
        message ||
        'Password was changed successfully! Please login again using new password!',
    }
  } catch (error) {
    return Promise.reject({
      status: false,
      message: error.message || 'Please check password and try again!',
    })
  }
}

export const AuthAPI = {
  login,
  refreshToken,
  getUserProfile,
  forgotPassword,
  changePassword,
  resetPassword,
  confirmResetPassword,
}

