import React, { useEffect, useRef, useState } from 'react'

export const useMergedState = <T>(
  initialValues: T,
): [
  T,
  (values: Partial<T>) => void,
  React.Dispatch<React.SetStateAction<T>>,
  React.MutableRefObject<T>,
] => {
  const [state, setState] = useState<T>(initialValues)
  const stateRef = useRef<T>(initialValues)
  const updateState = (values: Partial<T>) => {
    setState((prev) => ({ ...prev, ...values }))
  }

  useEffect(() => {
    stateRef.current = state
  }, [state])

  return [state, updateState, setState, stateRef]
}
