import React, { useContext, useEffect, useState } from 'react'
import { ToastOptions } from 'react-toastify'
import { Context } from '../index'

type Toast = {
  time: any
  message: React.ReactNode
}

type VisibleToaster = {
  type: string
  prevToast: Toast
  nextToast: Toast
  config?: ToastOptions
}

export const useToaster = () => {
  const context = useContext(Context)

  // false
  const [visibleToaster, setVisibleToaster] = useState<VisibleToaster>({
    type: 'success',
    prevToast: {
      time: 0,
      message: '',
    },
    nextToast: {
      time: 0,
      message: '',
    },
  })

  const toaster = context?.toaster
  const error = (
    message: React.ReactNode,
    config?: ToastOptions | undefined,
  ) => {
    if (!toaster) return
    settingToaster(message, 'error', config)
  }
  const success = (
    message: React.ReactNode,
    config?: ToastOptions | undefined,
  ) => {
    if (!toaster) return
    settingToaster(message, 'success', config)
  }

  const warning = (
    message: React.ReactNode,
    config?: ToastOptions | undefined,
  ) => {
    if (!toaster) return
    settingToaster(message, 'warning', config)
  }

  // ** used in case many toasters appear continuously
  const settingToaster = (
    message: React.ReactNode,
    type: string,
    config?: ToastOptions,
  ) => {
    const now = new Date().getTime()
    setVisibleToaster((prevState: VisibleToaster) => {
      return {
        type,
        prevToast: prevState.nextToast,
        nextToast: {
          time: now,
          message,
        },
        config,
      }
    })
  }
  useEffect(() => {
    let status = false
    if (visibleToaster.nextToast.message !== visibleToaster.prevToast.message) {
      status = true
    } else {
      if (
        visibleToaster.nextToast.time - visibleToaster.prevToast.time >
        1000
      ) {
        status = true
      } else {
        status = false
      }
    }
    if (status && visibleToaster.nextToast.message) {
      switch (visibleToaster.type) {
        case 'success':
          toaster?.success(
            visibleToaster.nextToast.message,
            visibleToaster.config,
          )
          break
        case 'error':
          toaster?.error(
            visibleToaster.nextToast.message === 'Network Error' ||
              visibleToaster.nextToast.message === 'Timed out, please try again'
              ? 'Network error. Check your internet connection and try again.'
              : visibleToaster.nextToast.message,
            visibleToaster.config,
          )
          break
        case 'warning':
          toaster?.warning(
            visibleToaster.nextToast.message,
            visibleToaster.config,
          )
          break
        default:
          break
      }
    }
    // eslint-disable-next-line
  }, [visibleToaster])
  // ** used in case many toasters appear continuously

  return {
    error,
    success,
    warning,
  }
}
