import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'store/slices/app'
import { HeaderContent } from 'models/app'

export const useHeaderTitle = (content: HeaderContent) => {
  const dispatch = useDispatch()

  const update = useCallback((content?: HeaderContent) => {
    if (!content) {
      return dispatch(actions.resetTitle())
    }
    dispatch(actions.updateTitle(content))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(actions.updateTitle(content))
    return () => {
      dispatch(actions.resetTitle())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return update
}
