import { FlexBox } from 'components/FlexBox'
import { useHeaderTitle } from 'hooks'
import React from 'react'
import { Button } from 'react-bootstrap'
import './style.scss'
export const UnexpectedError = () => {
  useHeaderTitle({ title: 'Unexpected Error' })

  const handleReloadPage = () => {
    window.location.reload()
  }
  return (
    <FlexBox
      className="flex-grow-1 unexpected-error"
      align="center"
      justify="center"
      direct="column"
    >
      <i className="mb-2 icon fas fa-exclamation-triangle fa-10x"></i>
      <p className="mb-5 h5 text-center">Oops! Something went wrong!</p>
      <Button onClick={handleReloadPage} size="sm">
        Try again
      </Button>
    </FlexBox>
  )
}
