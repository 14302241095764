export const scrollToTop = (smooth?: boolean) => {
  if ((document.documentElement.scrollTop || document.body.scrollTop) !== 0) {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: smooth ? 'smooth' : 'auto',
    })
  }
}

export const scrollToBottom = (smooth?: boolean) => {
  window.scrollTo({
    left: 0,
    top: document.body.scrollHeight,
    behavior: smooth ? 'smooth' : 'auto',
  })
}

export const scroller = {
  scrollToTop,
  scrollToBottom,
}
