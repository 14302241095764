import { FC, useEffect } from 'react'
import { Button } from 'react-bootstrap'

import { useBoolean } from 'hooks'
import { FlexBox } from 'components/FlexBox'
import { Loading } from 'components/Loading'

import { ModalProps, OriginModal } from './Modal'

export interface ConfirmDialogProps extends ModalProps {
  willUnmount?(): void
  close(): void
}
export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const { title, message, onCancel = () => {} } = props
  const [loading, showLoading, HideLoading] = useBoolean()
  const close = () => {
    if (loading) return
    props.close()
    props.afterClose && props.afterClose()
  }

  const keyboardClose = (event: KeyboardEvent) => {
    const { closeOnEscape, onKeypressEscape } = props
    const isKeyCodeEscape = event.keyCode === 27

    if (closeOnEscape && isKeyCodeEscape) {
      onKeypressEscape && onKeypressEscape(event)
      close()
    }
  }

  const handleOK = async () => {
    if (!props.onOk) return close()
    const okReturned = props.onOk()
    if (okReturned instanceof Promise) {
      showLoading()
      try {
        await okReturned
      } catch (error) {}
      HideLoading()
    }
    close()
  }

  const handleCancel = () => {
    onCancel()
    close()
  }

  useEffect(() => {
    document.addEventListener('keydown', keyboardClose, false)
    return () => {
      document.removeEventListener('keydown', keyboardClose, false)
      props.willUnmount && props.willUnmount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OriginModal
      visible={props.visible}
      footer={false}
      onClose={handleCancel}
      centered={props.centered || true}
    >
      <div className={`confirm-dialog ${props.type}`}>
        <Loading loading={loading} size="sm" animation="grow" opacity="0.8">
          <div className="confirm-dialog__content">
            <FlexBox>
              {props.icon && (
                <div className={`icon icon--${props.type} mr-3`}>
                  {props.icon}
                </div>
              )}
              <div className="flex-grow-1">
                <div className="title">{title}</div>
                <div className="message">{message}</div>
              </div>
            </FlexBox>
            <FlexBox className="actions" justify="end" align="end">
              {props.showCancel && (
                <Button
                  className="mx-1"
                  variant="secondary"
                  onClick={handleCancel}
                  size="sm"
                >
                  {props.cancelText || 'Cancel'}
                </Button>
              )}
              <Button
                className="mx-1"
                onClick={handleOK}
                variant={props.okType || 'primary'}
                size="sm"
              >
                {props.okText || 'OK'}
              </Button>
            </FlexBox>
          </div>
        </Loading>
      </div>
    </OriginModal>
  )
}

ConfirmDialog.defaultProps = {
  closeOnEscape: true,
  showCancel: false,
}
