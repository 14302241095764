import React from 'react'
import { Spinner } from 'react-bootstrap'
import classnames from 'classnames'
import './style.scss'

import { FlexBox } from 'components/FlexBox'

export interface LoadingProps {
  height?: number | string
  label?: string
  loading?: boolean
  minHeight?: number | string
  className?: string
  size?: 'sm'
  animation?: 'grow' | 'border'
  opacity?: string
  position?: 'body' | 'parent'
}
export const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <div
      className={classnames('loading-wrapper', props.className)}
      style={{ height: props.height, minHeight: props.minHeight }}
    >
      {props.children}
      <div
        className={classnames(
          'overlay',
          props.position === 'body' ? 'fullscreen' : '',
          { disabled: props.loading },
        )}
        style={{ display: props.loading ? 'block' : 'none' }}
      />
      {props.loading && (
        <FlexBox
          className={`spinner ${props.position === 'body' ? 'fullscreen' : ''}`}
          justify="center"
          align="center"
          direct="column"
        >
          {/* TODO: Implement variety spinner */}
          <Spinner animation={props.animation || 'border'} size={props.size} />
          {props.label && (
            <div className="mt-1 spinner__label">{props.label}</div>
          )}
        </FlexBox>
      )}
    </div>
  )
}
