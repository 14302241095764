import { BrowserRouter as Router } from 'react-router-dom'
import './App.scss'
import '@fortawesome/fontawesome-free/css/all.css'

import Routes from 'routes'
import { Header } from 'layout/Header'
import { GlobalContainer } from 'containers/Global'
import { NotificationContainer } from 'containers/Notification'
import { ErrorBoundary } from 'containers/ErrorBoundary'

function App() {
  return (
    <div className="App d-flex flex-column">
      <NotificationContainer>
        <GlobalContainer>
          <Router>
            <Header />
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Router>
        </GlobalContainer>
      </NotificationContainer>
    </div>
  )
}

export default App
