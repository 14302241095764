import { tokenStorage, refreshTokenStorage } from 'services/cookie'

export const saveToken = ({
  accessToken,
  refreshToken,
}: {
  accessToken?: string
  refreshToken?: string
}) => {
  if (accessToken) {
    tokenStorage.set(
      {
        accessToken,
      },
      1,
    )
  }
  if (refreshToken) {
    refreshTokenStorage.set(
      {
        refreshToken,
      },
      24 * 30,
    )
  }
}

export const clearToken = () => {
  tokenStorage.clear()
  refreshTokenStorage.clear()
}

