/* eslint-disable jsx-a11y/anchor-is-valid */
import { buildingLogo, logo } from 'assets/images'
import { confirmBox } from 'components/ConfirmLeavePage'
import { FlexBox } from 'components/FlexBox'
import { ENV } from 'configs/env'
import { useRouter } from 'hooks'
import { NavBar } from 'layout/NavBar'
import React, { useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import {
  visibleConfirmByControl,
  visibleConfirmByFunction,
} from 'store/slices/leavePage'
import './style.scss'

export const Header: React.FC = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const auth = useSelector((state: RootState) => {
    return state.auth
  })

  const layout = useSelector((state: RootState) => {
    return state.app
  })

  const visibleConfirm = useSelector(
    (state: RootState) => state.confirmLeavePage.visibleConfirm,
  )

  const triggerConfirmBox = (handle: () => void, message: string) => {
    dispatch(visibleConfirmByFunction())
    confirmBox(message, visibleConfirm, handle, () =>
      dispatch(visibleConfirmByControl()),
    )
  }

  const subTitle = useMemo(() => {
    if (!layout.subTitle) return undefined
    switch (layout.subTitle) {
      case 'login':
        return (
          <>
            If you have not created an account yet, then please{' '}
            <a href={ENV.CONTACT_URL} target="_blank" rel="noreferrer">
              {' '}
              Contact us
            </a>
          </>
        )
      default:
        break
    }
    return layout.subTitle
  }, [layout.subTitle])

  return (
    <header className="header">
      {layout.loading && (
        <div className="infinity-progress-bar layer-fixed">
          <div className="loader"></div>
          <div className="bg"></div>
        </div>
      )}
      <Container className="header__top-wrapper" fluid={true}>
        <Row className="header__top">
          <Col xs="6" md="5" lg="4" className="logo d-flex align-items-center">
            {auth.isAuthenticated ? (
              <a
                className="d-flex align-items-center logo__src"
                onClick={() =>
                  triggerConfirmBox(
                    () => router.push('/'),
                    'Uploading. Go to the Home page will interrupt upload processing. Do you want to go to the Home page anyway?',
                  )
                }
              >
                <img src={logo} alt="" className="img-fluid" />
              </a>
            ) : (
              <a className="d-flex align-items-center" href="#">
                <img src={logo} alt="" className="img-fluid" />
              </a>
            )}
          </Col>
          <Col
            xs="6"
            md="7"
            lg="8"
            className="d-flex justify-content-end align-items-center pr-4"
          >
            <NavBar triggerConfirmBox={triggerConfirmBox} />
          </Col>
        </Row>
      </Container>
      <FlexBox className="header__bottom" justify="center" align="center">
        <img src={buildingLogo} alt="" />
        <div className="header__title position-relative">
          {layout.title ? (
            <h3 className="font-weight-bold text-uppercase">{layout.title}</h3>
          ) : (
            <h3 className="font-weight-bold text-uppercase invisible">
              Loading...
            </h3>
          )}
          {subTitle && <p>{subTitle}</p>}
        </div>
      </FlexBox>
    </header>
  )
}
