import { FC, ReactNode } from 'react'
import { Button, Modal as BsModal } from 'react-bootstrap'

import './style.scss'

export type ModalProps = {
  visible?: boolean
  title?: string | React.ReactNode
  message?: string | React.ReactNode
  okText?: string
  cancelText?: string
  showCloseIcon?: boolean
  centered?: boolean
  backdrop?: 'static' | true | false
  footer?: ReactNode | false
  className?: string
  type?: 'warning' | 'info' | 'success' | 'confirm' | 'error' | 'warn'
  closeOnClickOutside?: boolean
  closeOnEscape?: boolean
  overlayClassName?: string
  okType?: 'primary' | 'danger' | 'warn'
  showCancel?: boolean
  icon?: ReactNode
  onClose?(): void
  onClickOutside?(): void
  onKeypressEscape?(e: KeyboardEvent): void
  onOk?(): void | Promise<void>
  onCancel?(): void
  afterClose?(): void
}

export const OriginModal: FC<ModalProps> = (props) => {
  return (
    <BsModal
      className={props.className}
      show={props.visible}
      centered={props.centered}
      backdrop={props.backdrop}
      onHide={props.onClose}
    >
      {props.title && (
        <BsModal.Header
          closeButton={props.showCloseIcon}
          onHide={props.onClose}
        >
          <BsModal.Title>{props.title}</BsModal.Title>
        </BsModal.Header>
      )}
      <BsModal.Body>{props.children}</BsModal.Body>
      {props.footer !== false &&
        (props.footer || (
          <BsModal.Footer>
            <Button variant="secondary" onClick={props.onClose}>
              {props.cancelText || 'Close'}
            </Button>
            <Button variant="primary" onClick={props.onOk}>
              {props.okText || 'OK'}
            </Button>
          </BsModal.Footer>
        ))}
    </BsModal>
  )
}

OriginModal.defaultProps = {
  showCloseIcon: true,
}
