import * as ReactDOM from 'react-dom'

import { LIST_ICON } from 'components/IconButton'

import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog'
import { ModalProps } from './Modal'

export type ModalFunc = (
  props: ModalProps,
) => {
  destroy: () => void
  update: (configUpdate: Partial<ModalProps>) => void
}

export type ModalStaticFunctions = Record<
  NonNullable<ModalProps['type']>,
  ModalFunc
>

export default function confirm(config: ModalProps) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  let currentConfig: ConfirmDialogProps = { ...config, close, visible: true }

  function render(props: ConfirmDialogProps) {
    setTimeout(() => {
      ReactDOM.render(<ConfirmDialog {...props} />, div)
    })
  }

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div)
    if (unmountResult && div.parentNode) {
      document.body.removeChild(div)
    }
  }

  function close() {
    currentConfig = {
      ...currentConfig,
      visible: false,
      afterClose: () => {
        if (typeof config.afterClose === 'function') {
          config.afterClose()
        }
        destroy()
      },
    }
    render(currentConfig)
  }

  function update(configUpdate: Partial<ModalProps>) {
    render({ ...currentConfig, ...configUpdate })
  }

  render(currentConfig)

  return {
    destroy: close,
    update,
  }
}

export function withWarn(props: ModalProps): ModalProps {
  return {
    showCancel: false,
    icon: LIST_ICON.info,
    ...props,
    type: 'warning',
  }
}

export function withInfo(props: ModalProps): ModalProps {
  return {
    showCancel: false,
    icon: LIST_ICON.info,
    ...props,
    type: 'info',
  }
}

export function withSuccess(props: ModalProps): ModalProps {
  return {
    showCancel: false,
    icon: LIST_ICON.success,
    ...props,
    type: 'success',
  }
}

export function withError(props: ModalProps): ModalProps {
  return {
    showCancel: false,
    icon: LIST_ICON.error,
    ...props,
    type: 'error',
  }
}

export function withConfirm(props: ModalProps): ModalProps {
  return {
    showCancel: true,
    icon: LIST_ICON.info,
    ...props,
    type: 'confirm',
  }
}
